import * as marked from "marked";

async function getWikiPage(url: string) {
    const siteInfoReq = await fetch("https://genesis-rules.thern.wtf/wiki" + encodeURIComponent(url))
    const info: unknown = await siteInfoReq.json();
    if (!isGiteaWikiInfo(info)) {
        throw new TypeError("info received from backend doesn't describe a Gitea Wiki Page")
    }
    const page = marked.parse(atob(info.content_base64));
    const sidebar = marked.parse(atob(info.sidebar));
    const footer = marked.parse(atob(info.footer));
    return {
        title: info.title.split("/").pop()!,
        page: typeof page == "string" ? page : await page,
        sidebar: typeof sidebar == "string" ? sidebar : await sidebar,
        footer: typeof footer == "string" ? footer : await footer
    }
}

export interface GiteaWikiInfo {
    title: string,
    html_url: string,
    sub_url: string,
    last_commit: {
        sha: string,
        author: Committer,
        committer: Committer,
        messager: string
    },
    content_base64: string,
    commit_count: number,
    sidebar: string,
    footer: string
}

function isGiteaWikiInfo(info: unknown): info is GiteaWikiInfo {
    if (!info) {
        return false
    };
    if (!(typeof (info as any).title == "string"
        && typeof (info as any).html_url == "string"
        && typeof (info as any).sub_url == "string"
        && typeof (info as any).content_base64 == "string"
        && typeof (info as any).commit_count == "number"
        && typeof (info as any).sidebar == "string"
        && typeof (info as any).footer == "string")) {
        return false
    }
    if (!(info as any).last_commit) {
        return false;
    }
    if (!(typeof (info as any).last_commit.message == "string"
        &&typeof (info as any).last_commit.sha == "string")) {
        return false
    }
    if (!isCommitter((info as any).last_commit.author)
        && isCommitter((info as any).last_commit.committer)) {
        return false;
    }
    return true;
}

export interface Committer {
    name: string,
    email: string,
    date: string
}

function isCommitter(committer: unknown): committer is Committer {
    return !!committer && typeof (committer as any).name == "string" && typeof (committer as any).email == "string" && typeof (committer as any).date == "string"
}

export default { getWikiPage } as const;