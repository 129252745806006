import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import GenesisWiki from './api/GenesisWiki';
import * as html_react from "html-react-parser"
import { useLocation, useNavigate } from 'react-router';

function App() {
  const [homepage, setHomePage] = useState<{title: String, page: string, sidebar: string, footer: string}>()
  // const [isPending, startTransition] = useTransition();
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(()=>{
    if(!homepage){
    const searchParams = new URLSearchParams(location.search)
    GenesisWiki.getWikiPage(searchParams.get("page")?"/"+searchParams.get("page") as string:"/Home").then(setHomePage, ()=>{
      navigate("/")
    })}
  }, [homepage, location.search, navigate])
  

  const replacer = (domNode: html_react.DOMNode)=>{
    if(domNode.type === "tag"){
      const props = html_react.attributesToProps(domNode.attribs)
      if(domNode.tagName === "a")
      return <a onClick={(ev)=>{
        ev.preventDefault();
        const params = new URLSearchParams();
        if("href" in props){
          params.append("page", encodeURIComponent(props.href as string))
          setHomePage(undefined)
        }
        navigate("/?"+params.toString())
      }} href='/' {...props} >{html_react.domToReact(domNode.children as unknown as html_react.DOMNode[], {replace: replacer})}</a>
    
      return {...domNode, children: domNode.children.map((node)=>html_react.domToReact([node as unknown as html_react.DOMNode], {replace: replacer}))}
    }
    return domNode
  }
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo"  />
        {homepage!==undefined&&<div className='wiki'>
          
          <div>
          <h1>{homepage.title}</h1>
            {html_react.default(homepage.page, {replace:replacer})}</div>
          <div>{html_react.default(homepage.sidebar, {replace: replacer})}</div>
          <div>{html_react.default(homepage.footer, {replace: replacer})}</div>
          </div>}
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
